import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";

import Alerts from "../common/Alerts";
import { useRef } from "react";
import userSettingsServices from "../../services/userSettings,services/userSettings,services";

export default function UpdateUserSettings({ userId }) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const buttonRef = useRef(null);

  //validation using yup
  const schema = yup
    .object({
      currentPassword: yup.string().required("Please enter current password"),
      newPassword: yup.string().required("Please enter new password"),
      confirmNewPassword: yup
        .string()
        .required("Please re-type your new password")
        .oneOf([yup.ref("newPassword")], "Passwords does not match"),
    })
    .required();

  // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (value) => {
    buttonRef.current.disabled = true;

    const updateData = {
      id: userId,
      currentPassword: value.currentPassword,
      newPassword: value.newPassword,
    };
    mutation.mutate(updateData);
    console.log(value);
  };
  // Handle the modal's open/close state
  const onModalState = (state) => {
    setIsModal(state);
    reset();
    setErrorMessage(null);
  };

  //adding voucherCode using react query
  const mutation = useMutation(userSettingsServices.updateUserPassword, {
    onSuccess: (data) => {
      console.log("Item changed successfully:", data);
      onModalState(false);
      dispatch(SnackBarMessageAction("Password changed Successfully"));
      // queryClient.invalidateQueries("voucherCode");
    },
    onError: (error) => {
      //Handle errors
      const responce = error;

      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  return (
    <>
      <Button
        className="common-button-style"
        onClick={() => onModalState(true)}
      >
        Change password
      </Button>
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Update Password</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Current password"
                fullWidth
                type="password"
                {...register("currentPassword")}
              />
              {errors.currentPassword && (
                <p className="error-message">
                  {errors.currentPassword.message}
                </p>
              )}
              <TextField
                label="New password"
                type="password"
                fullWidth
                {...register("newPassword")}
              />
              {errors.newPassword && (
                <p className="error-message">{errors.newPassword.message}</p>
              )}
              <TextField
                label="Confirm password"
                fullWidth
                type="password"
                {...register("confirmNewPassword")}
              />
              {errors.confirmNewPassword && (
                <p className="error-message">
                  {errors.confirmNewPassword.message}
                </p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => {
                    onModalState(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
