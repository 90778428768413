import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import { useForm } from "react-hook-form";
import brandServices from "../../../../services/brand.services/brandServices";
import { SnackBarMessageAction } from "../../../../store/commonSlice/commonSlice";
import Alerts from "../../../common/Alerts";
import { useParams } from "react-router-dom";
import { useRef } from "react";

const AddBrandModel = () => {
 
  const [isModal, setIsModal] = useState(false);
  const { brandid } = useParams();
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);

// Handle the modal's open/close state

  const onModalState = (state) => {
    setIsModal(state);
    reset();
    setErrorMessage(null);
  };
 // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
  
    const modifiedData = {
      id: brandid,
      model: data,
    };
    mutation.mutate(modifiedData);
    console.log(brandid, data);
  };

  // Define a mutation for creation using react-query
  const mutation = useMutation(brandServices.createBrandModel, {
    onSuccess: (data) => {
      console.log("Item added successfully:", data);

      onModalState(false);
      dispatch(SnackBarMessageAction("Model Added Successfully"));
      queryClient.invalidateQueries("brandModels");
    },
    onError: (error) => {
      const responce = error;
      console.log();
      console.error(responce.message);

      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });


  return (
    <>
      <Button
        className="common-button-style"
        onClick={() => onModalState(true)}
      >
        Add car brand model
      </Button>

      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">New brand model</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Model Name"
                fullWidth
                {...register("carModel", {
                  required: "Brand model name is required",
                })}
              />
              {errors.carModel && (
                <p className="error-message">{errors.carModel.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddBrandModel;
