import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCarService: null,

  isEdit: false,
};

const carServiceSlice = createSlice({
  name: "carService",
  initialState,
  reducers: {
    SelectedCarServiceAction: (state, action) => {
      state.selectedCarService = action.payload;
    },
  },
});
export const { SelectedCarServiceAction } = carServiceSlice.actions;

export default carServiceSlice.reducer;

export const SelectedCarService = (state) =>
  state.carService.selectedCarService;
