import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  selectedBrand: null,
  isEdit: false,
};

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    SelectedBrandAction: (state, action) => {
      state.selectedBrand = action.payload;
    },
  },
});
export const {
  
  SelectedBrandAction,
} = brandSlice.actions;

export default brandSlice.reducer;

export const SelectedBrand = (state) => state.brand.selectedBrand;