import axiosInstance from "../../helpers/auth.helper/auth.helper";

const additionalChargeServices = {
  createAdditionalChargeService: (data) =>
    axiosInstance.post("/admin/additionalCharge/add", data),
  getAllAdditionalChargeService: () => axiosInstance.get("/admin/additionalCharge/"),

  updateAdditionalChargeService: (data) =>
    axiosInstance.put(`/admin/additionalCharge/${data.id}`, data),
  deleteAdditionalChargeService: (data) =>
    axiosInstance.delete(`/admin/additionalCharge/${data}`),
};

export default additionalChargeServices;
