import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  snackBarMessage: null,
  isModal: false,
  isEditModal: false,
};
const commonSlice = createSlice({
  name: "common",

  initialState,
  reducers: {
    SnackBarMessageAction: (state, action) => {
      state.snackBarMessage = action.payload;
    },
    ModalAction: (state, action) => {
      state.isModal = action.payload;
    },
    EditModalAction: (state, action) => {
      state.isEditModal = action.payload;
    },
  },
});
export const { SnackBarMessageAction, ModalAction, EditModalAction } =
  commonSlice.actions;
export default commonSlice.reducer;
export const selectSnackBarMessage = (state) => state.common.snackBarMessage;
export const selectIsModal = (state) => state.common.isModal;
export const selectIsEditModal = (state) => state.common.isEditModal;
