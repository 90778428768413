
import DataTable from "../../components/common/DataTable";
import { useQuery } from "react-query";
import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import {  useSelector } from "react-redux";
import "../../styles/common.scss";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";
import userServices from "../../services/user.services/user.services";
import DeleteUser from "../../components/user-components/DeleteUser";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import UpdateUser from "../../components/user-components/UpdateUser";
import { useMemo } from "react";

const Users = () => {

  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["users"],
    userServices.getAllUsersService,
    {
      onError: (error) => {
        const responce = error;
        console.log();
        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => {
    return [
      {
        field: "id",

        flex: 1,

        headerName: "SL.NO",
        headerClassName: "column-header ",
      },
      {
        field: "name",
        headerName: "Name",

        flex: 1,

        headerClassName: "column-header ",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        field: "email",
        headerName: "Email",

        flex: 1,
        headerClassName: "column-header ",
        format: (value) => value.toLocaleString("en-US"),
      },

      {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 1,

        headerClassName: "column-header ",

        align: "left",
      },
      {
        field: "role",
        headerName: "Role",
        flex: 1,

        headerClassName: "column-header ",

        align: "left",

        renderCell: (params) => {
          return (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p>{params.row.role}</p>
              <UpdateUser user={params.row} />
            </div>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        sortable: false,
        flex: 1,

        headerClassName: "column-header ",
        format: (value) => value.toLocaleString("en-US"),
        renderCell: (params) => <DeleteUser user={params.row} />,
      },
    ];
  }, []);

  // Create a row data object for the data table
  function createData(id, name, email, phoneNumber, role) {
    return { id, name, email, phoneNumber, role };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data?.data?.data.map((item, index) => {
        return createData(
          item._id || index + 1,
          item.name,
          item.email,
          item.phoneNumber,
          item.role
        );
      });
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Users"
        SubHeaderName="Manage all users from here"
        SearchLabel="Search users"
      />

      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No users</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default Users;
