import axiosInstance from "../../helpers/auth.helper/auth.helper";

const userServices = {
  getAllUsersService: () => axiosInstance.get("/admin/user"),
  deleteUserService: (data) => axiosInstance.delete(`/admin/user/${data}`),
  updateUserService: (data) =>
    axiosInstance.put(`/admin/user/${data.id}`, data),
};

export default userServices;
