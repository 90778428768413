import DataTable from "../../components/common/DataTable";
import { useQuery } from "react-query";
import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import { useSelector } from "react-redux";
import "../../styles/common.scss";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import additionalChargeServices from "../../services/additionalCharges.services/additionalCharges.services";
import AddAdditionalCharge from "../../components/additional-charges-components/AddAdditionalCharge";
import DeleteAdditionalCharge from "../../components/additional-charges-components/DeleteAdditionalCharge";
import UpdateAdditionalCharge from "../../components/additional-charges-components/UpdateAdditionalCharge";
import { useMemo } from "react";

const AdditionalCharges = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["additionalCharge"],
    additionalChargeServices.getAllAdditionalChargeService,
    {
      onError: (error) => {
        const responce = error;
        console.log();
        console.error(responce.message);
      },
    }
  );

  const columns = [
    {
      field: "id",
      flex: 1,
      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "additionalChargeName",
      headerName: "Additional charge name",
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "additionalChargeCode",
      headerName: "Code",
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "additionalDescription",
      headerName: "Description",
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "additionalCost",
      headerName: "Additional cost",
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => (
        <UpdateAdditionalCharge additionalCharge={params.row} />
      ),

      align: "left",
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      sortable: false,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => (
        <DeleteAdditionalCharge additionalCharge={params.row} />
      ),
    },
  ];

  // Create a row data object for the data table
  function createData(
    id,
    additionalChargeName,
    additionalChargeCode,
    additionalCost,
    additionalDescription
  ) {
    return {
      id,
      additionalChargeName,
      additionalChargeCode,
      additionalDescription,
      additionalCost,
    };
  }

  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data?.data) {
      return data?.data?.data.map((item, index) => {
        return createData(
          item._id || index + 1,
          item.additionalChargeName,
          item.additionalChargeCode,
          item.additionalCost,
          item.additionalDescription
        );
      });
    }
    return [];
  }, [data, isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Additional charges"
        SubHeaderName="Manage all additional charges from here"
      />
      <div className="table-top-button">
        <AddAdditionalCharge />
      </div>

      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No users</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default AdditionalCharges;
