
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import brandServices from "../../../../services/brand.services/brandServices";
import PositionedSnackbar from "../../../../components/common/PositionedSnackbar";
import AddBrandModel from "../../../../components/car-components/brand-components/brand-model-component/AddBrandModel";
import DataTable from "../../../../components/common/DataTable";

import DeleteBrandModel from "../../../../components/car-components/brand-components/brand-model-component/DeleteBrandModel";
import TopBar from "../../../../components/common/TopBar";
import Loader from "../../../../components/common/Loader";
import { selectSnackBarMessage } from "../../../../store/commonSlice/commonSlice";
import BasicBreadcrumbs from "../../../../components/common/BreadCrumbs";
import { SelectedBrand } from "../../../../store/carSlice/brandSlice/brandSlice";
import { useMemo } from "react";
import UpdateBrandModel from "../../../../components/car-components/brand-components/brand-model-component/UpdateBrandModel";
const BrandModels = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const brand = useSelector(SelectedBrand);
  console.log(brand);

  const { brandid } = useParams();

  const { isLoading, isError, data } = useQuery(
    ["brandModels"],
    () => brandServices.getAllBrandModels(brandid),
    {
      onError: (error) => {
        const responce = error;

        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => [
    {
      field: "id",
      
      flex: 1,
      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "carModel",
      headerName: "Model Name",
      
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => <UpdateBrandModel carModel={params.row} />,
      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      
      flex: 1,
      sortable: false,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => (
        <DeleteBrandModel brandID={brandid} carModel={params.row} />
      ),
    },
  ],
  [brandid]
);


  // Create a row data object for the data table
  function createData(id, carModel) {
    return { id, carModel };
  }

// Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data?.data.carModels) {
      return data?.data?.data.carModels.map((item, index) =>
        createData(item._id || index + 1, item.carModel)
      );
    }
   
    return [];
  }, [data,isError]);

  console.log(data?.data?.data.carModels);
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }
  const routes = [
   
    {
      name: "Brands",
      to: "/cars/brands",
    },
    {
      name: brand?.brand,
      active: true,
    },
  ];

  return (
    <div className="table-main">
      <TopBar
        HeaderName={brand?.brand}
        SubHeaderName="Manage all car models from here"
        SearchLabel="Search Models"
      />
      <div className="table-top">
        <BasicBreadcrumbs routes={routes} />
        <AddBrandModel />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data.data.data.carModels.length === 0 ? (
          <div className="error-data">No models</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default BrandModels;
