import axiosInstance from "../../helpers/auth.helper/auth.helper";

function createBrand(data) {
  return axiosInstance.post("/admin/cars/brand/add", data);
}
function getAllBrands() {
  return axiosInstance.get("/admin/cars/brand");
}
function updateBrand(data) {
  return axiosInstance.put(`/admin/cars/brand/${data.id}`, data);
}

function deleteBrand(data) {
  return axiosInstance.delete(`/admin/cars/brand/${data}`);
}

function createBrandModel(data) {
  return axiosInstance.post(`/admin/cars/brand/${data.id}`, data.model);
}

function getAllBrandModels(brandID) {
  return axiosInstance.get(`/admin/cars/brand/${brandID}`);
}

function deleteBrandModel(data) {
  return axiosInstance.delete(
    `/admin/cars/brand/${data.brandID}/${data.modelID}`
  );
}

function updateBrandModel(data) {
  return axiosInstance.put(
    `/admin/cars/brand/${data.brandid}/${data.modelid}`,
    data
  );
}

const brandServices = {
  createBrand,
  getAllBrands,
  updateBrand,
  deleteBrand,
  createBrandModel,
  getAllBrandModels,
  deleteBrandModel,
  updateBrandModel,
};

export default brandServices;
