import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function DataTable({ columns, rows }) {
  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff !important",
        height: 550,
       
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={rows?.length === 0}
        sx={{
          "--DataGrid-overlayHeight": "50px",
          "&.MuiDataGrid-root": {
            border: "1px solid #ddd",
            background: "#fff !important",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid #e0e0e0",
            borderTop: "2px solid #ddd",
          },
          "& .MuiDataGrid-cell": {
            borderRight: "1px solid #e0e0e0",
            fontSize: "15px",
            fontWeight: 500,
          },
          "& .MuiDataGrid-toolbarContainer": {
            padding: "10px",
            "& .MuiInputBase-root": {
              border: "1px solid #ddd",
              padding: "5px",
              borderRadius: "4px",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,

            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
      />
    </Box>
  );
}
