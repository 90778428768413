import logo from "../../../assets/images/Artboard.svg";
import SideBarItem from "./SideBarItem";
import { useEffect, useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ConstructionIcon from "@mui/icons-material/Construction";
import DiscountIcon from "@mui/icons-material/Discount";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from '@mui/icons-material/Settings';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import SubSideBarItem from "./SubSideBarItem";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import "./leftsidebar.scss";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../../store/authSlice/authSlice";

export default function LeftSideBar() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSubSidebar, setShowSubSidebar] = useState(false);
 
  const location = useLocation(); // Get the current location

  const dispatch = useDispatch();
  const navigate = useNavigate();

 
//handle logout and redirect to login
  const handleLogout = () => {
    dispatch(logOutAction());
    navigate("/login");
  };

  useEffect(() => {
    location.pathname.startsWith("/cars")
      ? setShowSubSidebar(true)
      : setShowSubSidebar(false);
  }, [location.pathname]);

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <img className="logo" src={logo} alt="Icon" />

       
        <SideBarItem
          name="Cars"
          redirect="/cars/brands"
          icon={<DirectionsCarIcon />}
          isSelected={location.pathname.startsWith("/cars")}
          onClick={() => setSelectedItem(true)}
        />
        {/* Sub Sidebar */}
        {showSubSidebar && (
          <>
            <SubSideBarItem
              name="Brands"
              redirect="/cars/brands"
              isSelected={location.pathname.startsWith(
                "/cars/brands"
              )}
             
            />

            <SubSideBarItem
              name="Fuel Type"
              redirect="/cars/fueltype"
              isSelected={location.pathname.startsWith(
                "/cars/fueltype"
              )}
             
            />
            <SubSideBarItem
              name="Engine Origin"
              redirect="/cars/engineorigin"
              isSelected={location.pathname.startsWith(
                "/cars/engineorigin"
              )}
              
            />
            <SubSideBarItem
              name="Engine Size"
              redirect="/cars/enginesize"
              isSelected={location.pathname.startsWith(
                "/cars/enginesize"
              )}
              
            />
            {/* Add more sub-items as needed */}
          </>
        )}
        <SideBarItem
          name="Services"
          redirect="/services"
          icon={<ConstructionIcon />}
          isSelected={location.pathname.startsWith("/services")}
          //onItemClick={() => handleItemClick("services")}
        />
         <SideBarItem
          name="Appointments"
          redirect="/appointments"
          icon={<BookOnlineIcon />}
          isSelected={location.pathname.startsWith("/appointments")}
          //onItemClick={() => handleItemClick("services")}
        />
          <SideBarItem
          name="Locations"
          redirect="/locations"
          icon={<LocationOnIcon />}
          isSelected={location.pathname.startsWith("/locations")}
          //onItemClick={() => handleItemClick("services")}
        />
        <SideBarItem
          name="Users"
          redirect="/users"
          icon={<DirectionsCarIcon />}
          isSelected={location.pathname.startsWith("/users")}
          //  onItemClick={() => handleItemClick("Users")}
        />

        <SideBarItem
          name="Additional charges"
          redirect="/additionalcharges"
          icon={<LocalAtmIcon />}
          isSelected={location.pathname.startsWith(
            "/additionalcharges"
          )}
          // onItemClick={() => handleItemClick("Additional charges")}
        />

        <SideBarItem
          name="Voucher code"
          redirect="/vouchercode"
          icon={<DiscountIcon />}
          isSelected={location.pathname.startsWith("/vouchercode")}
          // onItemClick={() => handleItemClick("Promo code")}
        />
        <SideBarItem
          name="Settings"
         redirect="/settings"
          icon={<SettingsIcon />}
           isSelected={location.pathname.startsWith("/settings")}
          onClick={() => handleLogout()}
        /> 
        <NavLink
          style={{ textDecoration: "none" }}
          className={`sidebar-item `}
          to="/login"
          onClick={() => handleLogout()}
        >
          <LogoutIcon />
          <span>Log Out</span>
        </NavLink>
      </div>
    </div>
  );
}
