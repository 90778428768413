import { useMemo } from "react";
import DataTable from "../../../components/common/DataTable";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import brandServices from "../../../services/brand.services/brandServices";
import PositionedSnackbar from "../../../components/common/PositionedSnackbar";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../components/common/TopBar";
import Loader from "../../../components/common/Loader";
import AddBrand from "../../../components/car-components/brand-components/AddBrand";
import DeleteBrand from "../../../components/car-components/brand-components/DeleteBrand";
import { selectSnackBarMessage } from "../../../store/commonSlice/commonSlice";

import { SelectedBrandAction } from "../../../store/carSlice/brandSlice/brandSlice";
import UpdateBrand from "../../../components/car-components/brand-components/UpdateBrand";

const Brands = () => {
  const dispatch = useDispatch();
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["brands"],
    brandServices.getAllBrands,
    {
      onError: (error) => {
        const responce = error;
        console.log(responce);
        console.error(responce.message);
      },
    }
  );

  const columns = useMemo(() => [
    {
      field: "id",
      
      flex: 1,

      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "brand",
      headerName: "Brand Name",
      
      flex: 1,

      headerClassName: "column-header ",
    },
    {
      field: "models",
      headerName: "Models",
      sortable: false,
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => {
        return (
          <Link
            style={{ textDecoration: "none", color: "#f9ce00" }}
            to={`/cars/brands/${params.row.id}`}
            onClick={() => {
              dispatch(SelectedBrandAction(params.row));
            }}
          >
            View Models
          </Link>
        );
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,
      
      headerClassName: "column-header ",
      renderCell: (params) => {
        return <UpdateBrand brand={params.row} />;
      },

      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => <DeleteBrand brand={params.row} />,
    },
  ]);

  // Create a row data object for the data table
  function createData(id, brand) {
    return { id, brand };
  }


  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data.data) {
      return data.data.data.map((item, index) =>
        createData(item._id || index+1, item.brand)
      );
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }


  return (
    <div className="table-main">
      <TopBar
        HeaderName="Brands"
        SubHeaderName="Manage all car brands from here"
        SearchLabel="Search brands"
      />
      <div className="table-top-button">
       
        <AddBrand />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No Brands</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default Brands;
