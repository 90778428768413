import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";
import Alerts from "../common/Alerts";
import additionalChargeServices from "../../services/additionalCharges.services/additionalCharges.services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef } from "react";

export default function AddAdditionalCharge() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const buttonRef = useRef(null);

  // Define validation schema for the form using yup
  const schema = yup.object({
    additionalChargeName: yup
      .string()
      .required("Additional charge name is required"),
    additionalCost: yup
      .number()
      .required("Additional cost is required")
      .typeError("Additional cost must be a valid number")
      .positive("Please enter a positive number"),
    additionalChargeCode: yup.string().required("Code is required"),
  });

  // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // Function to handle form submission
  const onSubmit = (value) => {
    buttonRef.current.disabled = true;
    console.log(value);
    mutation.mutate(value);
  };

  // Handle the modal's open/close state
  const onModalState = (state) => {
    setIsModal(state);
    reset();
    setErrorMessage(null);
  };

  // Define a mutation for creation using react-query
  const mutation = useMutation(
    additionalChargeServices.createAdditionalChargeService,
    {
      onSuccess: (data) => {
        console.log("Item changed successfully:", data);
        onModalState(false);
        dispatch(SnackBarMessageAction("Additional charge added Successfully"));
        queryClient.invalidateQueries("additionalCharge");
      },
      onError: (error) => {
        const responce = error;
        console.error(responce.message);
        if (responce?.response?.status === 500) {
          setErrorMessage(responce?.response?.data.message);
        } else {
          console.log(error);
          setErrorMessage("network Error");
        }
      },
    }
  );

  return (
    <>
      <Button
        className="common-button-style"
        onClick={() => onModalState(true)}
      >
        Add additional charge
      </Button>
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">
            New additional charge
          </DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Additional charge name"
                fullWidth
                {...register("additionalChargeName")}
              />
              {errors.additionalChargeName && (
                <p className="error-message">
                  {errors.additionalChargeName.message}
                </p>
              )}

              <TextField
                label="Additional charge code"
                fullWidth
                {...register("additionalChargeCode")}
              />
              {errors.additionalChargeCode && (
                <p className="error-message">
                  {errors.additionalChargeCode.message}
                </p>
              )}
              <TextField
                label="Additional cost "
                fullWidth
                {...register("additionalCost")}
              />
              {errors.additionalCost && (
                <p className="error-message">{errors.additionalCost.message}</p>
              )}

              <TextField
                label="Description "
                fullWidth
                {...register("additionalDescription")}
              />

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => {
                    onModalState(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
