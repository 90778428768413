import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/login/Login";

import Layout from "./components/layout/Layout";
import ErrorPage from "./pages/errorPage/ErrorPage";
import CarServices from "./pages/carServices";
import BrandModels from "./pages/cars/brands/brandModels";
import CarSubServices from "./pages/carServices/carSubServices";
import Brands from "./pages/cars/brands";
import Cars from "./pages/cars";
import EngineOrigin from "./pages/cars/engineOrigin";
import EngineSize from "./pages/cars/engineSize";
import FuelType from "./pages/cars/fuelType";
import Users from "./pages/users";
import AdditionalCharges from "./pages/additional-charges";
import Appointments from "./pages/appointments";
import Locations from "./pages/locations";
import VoucherCode from "./pages/voucher-code";
import UserSettings from "./pages/user-settings";

const Router = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const checkIfUserIsAuthenticated = () => {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Check whether the user is authenticated here, and set the state accordingly
    const userIsAuthenticated = checkIfUserIsAuthenticated();
    setIsAuthenticated(userIsAuthenticated);

    // If the user is not authenticated, redirect to the login page
    if (!userIsAuthenticated) {
      navigate("/login");
    }
  }, [navigate]);
  return !isAuthenticated ? (
    <Routes>
      <Route path="/login" element={<Login />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="cars" element={<Cars />}>
          <Route path="brands" element={<Brands />} />
          <Route path="brands/:brandid" element={<BrandModels />} />
          <Route path="enginesize" element={<EngineSize />} />
          <Route path="fueltype" element={<FuelType />} />
          <Route path="engineorigin" element={<EngineOrigin />} />
        </Route>
        <Route path="services" element={<CarServices />} />
        <Route path="services/:serviceid" element={<CarSubServices />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="locations" element={<Locations />} />
        <Route path="settings" element={<UserSettings />} />
        <Route path="users" element={<Users />} />
        <Route path="additionalcharges" element={<AdditionalCharges />} />
        <Route path="vouchercode" element={<VoucherCode />} />

        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
  
};

export default Router;
