import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, InputLabel, MenuItem, Select } from "@mui/material";
import {  useState } from "react";
import { useForm } from "react-hook-form";
import {  Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";

import Alerts from "../common/Alerts";
import userServices from "../../services/user.services/user.services";
import { useRef } from "react";

export default function UpdateUser({ user }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const buttonRef = useRef(null);
 
  //validation and form using react hook form
  const [defaultRole, setDefaultRole] = useState(user.role);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

 // Function to handle form submission
  const onSubmit = async(value) => {
    buttonRef.current.disabled = true;
    
    const updateData = {
      id: user?.id,
      role: value.role,
    };
    mutation.mutate(updateData);
    console.log(updateData);
  };

  
// Function to control the dialog's open and close state
  const onModalState = (state) => {
    setIsModal(state);
    setDefaultRole(user.role);
    reset();
  };

  //updating user using react query
  const mutation = useMutation(userServices.updateUserService, {
    onSuccess: (data) => {
      console.log("Item changed successfully:", data);
      dispatch(SnackBarMessageAction("User role changed Successfully"));
      queryClient.invalidateQueries("users");
      setIsModal(false);
    },
    onError: (error) => {
      const responce = error;
      console.error(responce.message);
      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Update user role</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => {
              onModalState(false);
            
            }}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError ? <Alerts name={errorMessage} /> : ""}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                value={defaultRole}
                labelId="demo-simple-select-label"
                {...register("role", {
                  required: "Role is required",
                })}
                onChange={(role) => setDefaultRole(role.target.value)}
              >
                <MenuItem value="admin">admin</MenuItem>
                <MenuItem value="user">user</MenuItem>
              </Select>
              {errors.role && (
                <p className="error-message">{errors.role.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => {
                    onModalState(false);
                  
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
