
const TopBar = ({ HeaderName, SubHeaderName }) => {
  return (
    <div className="topbar">
      <div className="topbar-items">
        <h1>{HeaderName}</h1>
        <p>{SubHeaderName}</p>
      </div>
    </div>
  );
};

export default TopBar;
