import DataTable from "../../components/common/DataTable";
import { useQuery } from "react-query";

import Loader from "../../components/common/Loader";
import TopBar from "../../components/common/TopBar";
import { useSelector } from "react-redux";
import { selectSnackBarMessage } from "../../store/commonSlice/commonSlice";
import PositionedSnackbar from "../../components/common/PositionedSnackbar";
import "../../styles/common.scss";
import appointmentsServices from "../../services/appointments.services/appointments.services";
import { useMemo } from "react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import moment from "moment";
import ViewAppointMent from "../../components/appointments-components/ViewAppointMent";
const Appointments = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const { isLoading, isError, data } = useQuery(
    ["appointments"],
    appointmentsServices.getAllAppointmentsService,
    {
      onError: (error) => {
        const responce = error;
        console.log();
        console.error(responce.message);
      },
    }
  );

  const columns = [
    {
      field: "id",
      flex: 1,
      headerName: "Appointment ID",
      headerClassName: "column-header ",
    },
    {
      field: "appointmentDate",
      headerName: "Date",
      flex: 0.5,
      headerClassName: "column-header ",
    },
    {
      field: "appointmentTime",
      headerName: "Time",
      flex: 0.4,
      headerClassName: "column-header ",

      format: (value) => moment(value.params.appointmentTime).format("hh:mm A"),
    },

    {
      field: "customerNumber",
      headerName: "Customer phone ",
      flex: 0.5,
      headerClassName: "column-header ",
      align: "left",
    },

    {
      field: "vehicleNumber",
      headerName: "Vehicle number",
      flex: 0.6,
      headerClassName: "column-header ",
      align: "left",
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 0.4,
      headerClassName: "column-header ",
      align: "left",
    },
    {
      field: "paid",
      headerName: "Payment status",
      flex: 0.5,
      headerClassName: "column-header ",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      headerClassName: "column-header ",
      align: "left",
      format: (value) => moment(value.params.createdAt).format("llll"),
    },
    {
      field: "view",
      headerName: "View Details",
      flex: 0.3,
      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => <ViewAppointMent appointment={params.row} />,
      align: "left",
    },
  ];

  // Create a row data object for the data table
  function createData(
    id,
    appointmentDate,
    appointmentTime,
    customerNumber,
    locationCode,
    vehicleNumber,
    customerId,
    customerName,
    amount,
    paid,
    createdAt,
    appointmentEndTime,
    incadeaId
  ) {
    return {
      id,
      appointmentDate,
      appointmentTime,
      customerNumber,
      locationCode,
      vehicleNumber,
      customerId,
      customerName,
      amount,
      paid,
      createdAt,
      appointmentEndTime,
      incadeaId,
    };
  }

  // Generate rows for the data table using data retrieved from the API
  console.log(data);
  const rows = useMemo(() => {
    if (!isError && data?.data?.data) {
      return data.data.data.map((item, index) => {
        return createData(
          item._id || index + 1,
          item.appointmentDate,
          item.appointmentTime,
          item.customerNumber,
          item.locationCode,
          item.vehicleNumber,
          item.customerId,
          item.customerName,
          item.amount,
          item.paid == true ? "Paid" : "Unpaid",
          moment(item.createdAt).format("llll"),
          item.appointmentEndTime,
          item?.incadeaId
        );
      });
    }
    return [];
  }, [isError, data]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }
  console.log(rows);

  return (
    <div className="table-main">
      <TopBar
        HeaderName="Appointments"
        SubHeaderName="Manage all appointments from here"
      />

      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data.data.length === 0 ? (
          <div className="error-data">No users</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default Appointments;
