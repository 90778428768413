import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  days: null,
  isEdit: false,
};

const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    SelectedDayOfWeeks: (state, action) => {
      state.days = action.payload;
    },
  },
});
export const { SelectedDayOfWeeks } = voucherSlice.actions;

export default voucherSlice.reducer;

export const dayOfWeeks = (state) => state.voucher.days;
