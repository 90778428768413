import axiosInstance from "../../helpers/auth.helper/auth.helper";

const carServiceServices = {
  createCarService: (data) => axiosInstance.post("/admin/services/", data),
  getAllCarServices: () => axiosInstance.get("/admin/services/"),
  updateCarServices: (data) =>
    axiosInstance.put(`/admin/services/${data.id}`, data),
  deleteCarService: (data) => axiosInstance.delete(`/admin/services/${data}`),
  createCarSubService: (data) =>
    axiosInstance.post(`/admin/services/${data.id}`, data.subService),
  getAllCarSubServices: (serviceID) =>
    axiosInstance.get(`/admin/services/${serviceID}`),
  deleteCarSubServices: (data) =>
    axiosInstance.delete(
      `/admin/services/${data.serviceID}/${data.subServiceID}`
    ),
  updateCarSubServices: (data) =>
    axiosInstance.put(`/admin/services/${data.serviceid}/${data.id}`,data),
};

export default carServiceServices;
