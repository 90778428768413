import React from "react";

import "../../styles/main.scss";
import { Outlet } from "react-router-dom";
import RightSidebar from "./right-sidebar/RightSidebar";
import LeftSideBar from "./left-sidebar/LeftSideBar";
import SidebarMenu from "./left-sidebar/SidebarMenu";

const Layout = () => {
  return (
    <div className="content">
      <div className="left-sidebar">
        <LeftSideBar />
      </div>
      <div className="main">
        <SidebarMenu />
        <Outlet />
      </div>
      <RightSidebar />
    </div>
  );
};

export default Layout;
