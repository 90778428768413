
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {  useParams } from "react-router-dom";
import carServiceServices from "../../../services/carService.services/carServiceServices";
import PositionedSnackbar from "../../../components/common/PositionedSnackbar";
import DataTable from "../../../components/common/DataTable";
import TopBar from "../../../components/common/TopBar";
import Loader from "../../../components/common/Loader";
import DeleteSubService from "../../../components/car-services-components/car-sub-service-components/DeleteCarSubService";
import "../../../styles/common.scss"
import AddCarSubService from "../../../components/car-services-components/car-sub-service-components/AddCarSubService";
import { selectSnackBarMessage } from "../../../store/commonSlice/commonSlice";
import BasicBreadcrumbs from "../../../components/common/BreadCrumbs";
import { SelectedCarService } from "../../../store/carServiceSlice/carServiceSlice";
import UpdateCarSubService from "../../../components/car-services-components/car-sub-service-components/UpdateCarSubService";
import { useMemo } from "react";

const CarSubServices = () => {
  const snackBarMessage = useSelector(selectSnackBarMessage);
  const carService = useSelector(SelectedCarService);
  const { serviceid } = useParams();

  //fetching all car subservices

  const { isLoading, isError, data } = useQuery(
    ["carSubService"],
    () => carServiceServices.getAllCarSubServices(serviceid),
    {
      onError: (error) => {
        const responce = error;

        console.error(responce.message);
      },
    }
  );

  // creating columns for table
  const columns = useMemo(() => [
    {
      field: "id",
      flex: 1,
      headerName: "SL.NO",
      headerClassName: "column-header ",
    },
    {
      field: "subServiceName",
      headerName: "Sub service name",
     
      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "subServiceCode",
      headerName: "Sub service code",

      flex: 1,
      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "price",
      headerName: "Price",
    
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      sortable: false,
      headerClassName: "column-header ",
      renderCell: (params) => <UpdateCarSubService subService={params.row} />,

      align: "left",
      render: (value) => <button>{value.toLocaleString("en-US")}</button>,
    },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      flex: 1,

      headerClassName: "column-header ",
      format: (value) => value.toLocaleString("en-US"),
      renderCell: (params) => (
        <DeleteSubService serviceID={serviceid} subService={params.row} />
      ),
    },
  ]);



  // Create a row data object for the data table
  function createData(id, subServiceName,subServiceCode, price) {
    return { id, subServiceName,subServiceCode, price };
  }


  // Generate rows for the data table using data retrieved from the API
  const rows = useMemo(() => {
    if (!isError && data?.data?.data.subServices) {
      return data?.data?.data.subServices.map((item, index) =>
        createData(
          item._id || index + 1,
          item.subServiceName,
          item.subServiceCode,
          item.price
        )
      );
    }
    return [];
  }, [data,isError]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div className="error-data">Error fetching data</div>;
  }

  //breadCrumb routes

  const routes = [
  
    {
      name: "Services",
      to: "/services",
    },
    {
      name: carService?.serviceName,
      active: true,
    },
  ];

  return (
    <div className="table-main">
      <TopBar
        HeaderName={carService?.serviceName}
        SubHeaderName="Manage all Sub Services from here"
        SearchLabel="Search Sub Services"
      />
      <div className="table-top">
        <BasicBreadcrumbs routes={routes} />
        <AddCarSubService />
      </div>
      {snackBarMessage && <PositionedSnackbar message={snackBarMessage} />}

      <div className="data-table">
        {data?.data?.data.subServices.length === 0 ? (
          <div className="error-data">No sub services</div>
        ) : (
          <DataTable columns={columns} rows={rows} />
        )}
      </div>
    </div>
  );
};

export default CarSubServices;
