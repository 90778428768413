import axiosInstance from "../../helpers/auth.helper/auth.helper";

const userSettingsServices = {
  getUserService: (userId) =>
    axiosInstance.get(`/admin/user/settings/${userId}`),
  updateUserPassword: (data) =>
    axiosInstance.put(`/admin/user/settings/${data.id}`, data),
};

export default userSettingsServices;
