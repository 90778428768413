import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LeftSideBar from "./LeftSideBar";
import { Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react";

const ITEM_HEIGHT = 100;

export default function SidebarMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:768px)'); // Adjust the screen size as needed

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Conditionally add the onClose handler based on screen size
    if (isSmallScreen) {
      setAnchorEl(null); // Close the menu on small screens
    }
  }, [isSmallScreen]);

  return (
    <div className="hamburger-menu">
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <Box sx={{ width: 200 }} onClick={handleClose}>
          <LeftSideBar />
        </Box>
      </Menu>
    </div>
  );
}
