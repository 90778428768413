import axiosInstance from "../../helpers/auth.helper/auth.helper";

const engineSizeServices = {
  createEngineSizeService: (data) => axiosInstance.post("/admin/cars/engineSize/add", data),
  getAllEngineSizeService: () => axiosInstance.get("/admin/cars/engineSize/"),
  updateEngineSizeService: (data) => axiosInstance.put(`/admin/cars/engineSize/${data.id}`, data),
  deleteEngineSizeService: (data) => axiosInstance.delete(`/admin/cars/engineSize/${data}`),
};

export default engineSizeServices;
