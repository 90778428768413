import axiosInstance from "../../helpers/auth.helper/auth.helper";

const locationServices = {
  createLocationService: (data) =>
    axiosInstance.post("/admin/location/add", data),
  getAllLocationService: () => axiosInstance.get("/admin/location/"),

  updateLocationService: (data) =>
    axiosInstance.put(`/admin/location/${data.id}`, data),
  deleteLocationService: (data) =>
    axiosInstance.delete(`/admin/location/${data}`),
};

export default locationServices;
