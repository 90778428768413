import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import {  SnackBarMessageAction } from "../../store/commonSlice/commonSlice";

export default function PositionedSnackbar({ message }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  //set a snack bar timer to run
  React.useEffect(() => {
    if (message) {
      setOpen(true);

      const timer = setTimeout(() => {
        setOpen(false);
        dispatch(SnackBarMessageAction(null));
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch,message]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={message}
    />
  );
}
