import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackBarMessageAction } from "../../../store/commonSlice/commonSlice";
import { useMutation, useQueryClient } from "react-query";
import carServiceServices from "../../../services/carService.services/carServiceServices";
import Alerts from "../../common/Alerts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMemo } from "react";
import { useRef } from "react";

const UpdateCarSubService = ({ subService }) => {
  const dispatch = useDispatch();
  const { serviceid } = useParams();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);

  const [isModal, setIsModal] = useState(false);
  const [defaultValue, setDefault] = useState(subService);
  const buttonRef = useRef(null);

  //creating carsubservice using react query
  const mutation = useMutation(carServiceServices.updateCarSubServices, {
    onSuccess: (data) => {
      console.log("Item added successfully:", data);
      setIsModal(false);
      dispatch(SnackBarMessageAction("car sub service updated Successfully"));
      queryClient.invalidateQueries("carSubService");
    },
    onError: (error) => {
      const responce = error;
      console.log();
      console.error(responce.message);

      if (responce?.response?.status === 500) {
        setErrorMessage(responce?.response?.data.message);
      } else {
        console.log(error);
        setErrorMessage("network Error");
      }
    },
  });

  //validation using yup
  const schema = yup
    .object({
      subServiceName: yup.string().required("Sub Service Name is required"),
      subServiceCode: yup.string().required("Sub service code is required"),
      price: yup
        .number()
        .typeError("Price must be a valid number")
        .positive("Please enter a positive number")
        .required("Price is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

  //creating carsubservice using react query
  const onSubmit = async (data) => {
    buttonRef.current.disabled = true;

    const updatedData = {
      serviceid: serviceid,
      ...data,
    };
    try {
      await mutation.mutateAsync(updatedData);
      setDefault(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle opening and closing the dialog
  const onModalState = (state) => {
    setIsModal(state);
    setErrorMessage(null);
    !state && reset(defaultValue);
  };

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">Update sub service</DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Sub service Name"
                fullWidth
                {...register("subServiceName")}
              />
              {errors.subServiceName && (
                <p className="error-message">{errors.subServiceName.message}</p>
              )}
              <TextField
                label="Sub service code"
                fullWidth
                {...register("subServiceCode")}
              />
              {errors.subServiceCode && (
                <p className="error-message">{errors.subServiceCode.message}</p>
              )}
              <TextField label="Price" fullWidth {...register("price")} />
              {errors.price && (
                <p className="error-message">{errors.price.message}</p>
              )}

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateCarSubService;
