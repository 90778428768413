import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMemo } from "react";
import { SnackBarMessageAction } from "../../store/commonSlice/commonSlice";
import { yupResolver } from "@hookform/resolvers/yup";

import Alerts from "../common/Alerts";
import additionalChargeServices from "../../services/additionalCharges.services/additionalCharges.services";
import { useRef } from "react";

export default function UpdateAdditionalCharge({ additionalCharge }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const [defaultValue, setDefault] = useState(additionalCharge);
  const buttonRef = useRef(null);

  // Validation schema for the form using Yup

  const schema = yup.object({
    additionalChargeName: yup
      .string()
      .required("Additional charge name is required"),

    additionalCost: yup
      .number()
      .required("Additional cost is required")
      .typeError("Additional cost must be a valid number")
      .positive("Please enter a positive number"),
    additionalChargeCode: yup.string().required("Code is required"),
  });

  // Set up the form using react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

  // Function to handle form submission
  const onSubmit = async (value) => {
    buttonRef.current.disabled = true;
    const updateData = {
      id: additionalCharge?.id,
      additionalChargeName: value.additionalChargeName,
      additionalCost: value.additionalCost,
      additionalChargeCode: value.additionalChargeCode,
      additionalDescription: value.additionalDescription,
    };
    try {
      await mutation.mutateAsync(updateData);
      setDefault(updateData);
    } catch (error) {
      console.error(error);
    }
  };

  // Function to control the dialog's open and close state
  const onModalState = (state) => {
    setIsModal(state);
    setErrorMessage(null);
    !state && reset(defaultValue);
  };

  // Updation using React Query
  const mutation = useMutation(
    additionalChargeServices.updateAdditionalChargeService,
    {
      onSuccess: (data) => {
        setIsModal(false);
        dispatch(
          SnackBarMessageAction("Additional charge changed Successfully")
        );
        queryClient.invalidateQueries("additionalCharge");
      },
      onError: (error) => {
        const responce = error;
        console.error(responce.message);
        if (responce?.response?.status === 500) {
          setErrorMessage(responce?.response?.data.message);
        } else {
          console.log(error);
          setErrorMessage("network Error");
        }
      },
    }
  );

  return (
    <>
      <EditOutlinedIcon
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onModalState(true)}
      />
      <Dialog className="dialog" open={isModal}>
        <div className="dialog-top">
          <DialogTitle className="dialog-title">
            Update additional charge
          </DialogTitle>
          <CloseIcon
            className="close-icon"
            onClick={() => onModalState(false)}
          />
        </div>
        <Divider light />
        <DialogContent>
          <div className="form-container">
            {mutation.isError && errorMessage ? (
              <Alerts name={errorMessage} />
            ) : (
              ""
            )}
            <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
              <TextField
                label="Additional charge name"
                fullWidth
                {...register("additionalChargeName")}
              />
              {errors.additionalChargeName && (
                <p className="error-message">
                  {errors.additionalChargeName.message}
                </p>
              )}

              <TextField
                label="Additional charge code"
                fullWidth
                {...register("additionalChargeCode")}
              />
              {errors.additionalChargeCode && (
                <p className="error-message">
                  {errors.additionalChargeCode.message}
                </p>
              )}
              <TextField
                label="Additional cost "
                fullWidth
                {...register("additionalCost")}
              />
              {errors.additionalCost && (
                <p className="error-message">{errors.additionalCost.message}</p>
              )}
              <TextField
                label="Description "
                fullWidth
                {...register("additionalDescription")}
              />

              <div className="form-buttons">
                <Button
                  className="button-main"
                  variant="contained"
                  onClick={() => onModalState(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  className="button-main"
                  ref={buttonRef}
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
