import axiosInstance from "../../helpers/auth.helper/auth.helper";

const voucherCodeServices = {
  createVoucherCodeService: (data) =>
    axiosInstance.post("/admin/voucherCode/add", data),
  getAllVoucherCodeService: () => axiosInstance.get("/admin/voucherCode/"),
  deleteVoucherCodeService: (data) =>
    axiosInstance.delete(`/admin/voucherCode/${data}`),
  updateVoucherCodeService: (data) =>
    axiosInstance.put(`/admin/voucherCode/${data.id}`, data),
};

export default voucherCodeServices;
