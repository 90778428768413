import React from "react";
import { NavLink } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./leftsidebar.scss";

const SubSideBarItem = ({ name, redirect, isSelected }) => {
 
  return (
    <NavLink
      style={{
        textDecoration: "none",
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "6px",
      }}
      className={`sidebar-item ${
        isSelected ? "sidebar-item-selected" : ""
      }`}
      to={redirect}
    
    >
      <FiberManualRecordIcon style={{ fontSize: "10px" }} />

      <span>{name}</span>
    </NavLink>
  );
};
export default SubSideBarItem;
