import React from "react";
import { Outlet } from "react-router-dom";

const Cars = () => {
  return (
   
      <Outlet />
  
  );
};

export default Cars;
